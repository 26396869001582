<template>
 <section class="page_title  pdt_80  pdb_40">
               <div class="container">
                  <div class="row">
                     <!---------row----------->
                     <div class="col-lg-12 col-md-12 text-center">
                        <!---------col----------->
                        <!---------col-end---------->
                     </div>
                     <!----------row-end---------->
                  </div>
               </div>
</section>
<section class="blog_detail no_sidebar  pdb_100">
    <div class="container">
        <div class="row">
            <!---------row----------->
            <div class="col-lg-12 col-md-12">
            <!---------col----------->
            <div class="featured_image">
                <div class="image_box">
                    <img src="@/assets/image/news/blogpartnership.jpg" style="width:100%; height:420px !important; object-fit:cover; object-position:top" class="img-fluid" alt="img" />
                </div>
            </div>
            <!---------col-end---------->
            </div>
            <!----------row-end---------->
        </div>
        <div class="row">
            <!---------row----------->
            <div class="col-lg-2 col-md-12">
            <!---------enpty_column----------->
            </div>
            <div class="col-lg-8 col-md-12">
            <!---------col----------->
            <div class="blog_detail_content">
                <!-----blog_upper_box------->
                <div class="blog_detail_upper_box">
                    <h1>MakSPH leads a partnership of five African universities to
generate evidence to support the COVID-19 response</h1>
                    <ul class="bread_crumb text-center">
                        <li class="bread_crumb-item"><a href="#">   <i class="linearicons-home4 home_icon"></i> </a></li>
                        <li class="bread_crumb-item"><a href="#">Media</a></li>
                        <li class="bread_crumb-item"><a href="#">News</a></li>
                        <li class="bread_crumb-item active">MakSPH leads a partnership of five African universities</li>
                    </ul>
                    <ul class="blog_details_elements">
                        <li><small class="linearicons-calendar-full"></small> 17 Apr 2020</li>
                        <li><small class="linearicons-user"></small> Author: <a href="#">Davidson Ndyabahika</a></li>
                        <li><small class="linearicons-clipboard-empty"></small> Category: <a href="#">Pandemics</a></li>
                    </ul>
                    <h6>MakSPH is in the second year of the assessment of the COVID-19 response in Africa in partnership with several universities including University of Cheikh Anta Diop Dakar, Senegal; University of Ibadan, Nigeria; University of Kinshasa, DRC; and the University of Ghana.</h6>
                </div>
                <!-----blog_upper_box-end------>
                <!-----blog_lower_box------->
                <div class="blog_detail_lower_box">
                    <p><span class="big_font mr-2"> W </span><span class="bg_font"> W </span> ith funding from the Bill and Melinda Gates Foundation and Gates Ventures Exemplars in COVID-19 Response (ECR), the project has documented interventions implemented as part of the response and their effectiveness.
                    </p>
                    <p>This includes testing and surveillance approaches, the effect of the COVID-19 pandemic on maintenance of essential health services, the consequences (intended and unintended) of implementing the non pharmaceutical interventions in pandemic control, and the role of trust in vaccine uptake.</p>
                    <div class="text_box">
                        <h2>Project Progress</h2>
                        <p>The project has generated evidence including best practices, challenges, and lessons learned in countries’ response which has provided technical guidance and support to the respective Ministries of Health. For example, in Uganda, the study findings informed the Nigeria, DRC, Uganda, Ghana, Senegal, Democratic Republic of Congo revision of the guidelines on the Continuity of Essential Health Services to bridge gaps. The project has also started a virtual community of practice of African institutions on pandemic preparedness
                         focused on sharing evidence, expertise, peer learning and strengthening capacities for COVID-19 control and the broader disease preparedness and response.</p>
                        <p>Furthermore, findings from the COVID-19 assessment project have been widely disseminated at
various local, regional, and international forums including webinars, conferences, and online publications. These avenues include: The Consortium of Universities in Global Health 2021 conference, Regional World Health Summit in Kampala, Uganda; the 1st International Conference on Public Health in Africa, and the Galien Afrique 2021 conference in Senegal. The project has also engaged and shared findings with policy makers and global funding institutions including the World Health Organization and the World Bank Global Financing Facility.</p>
                    <p>The other project partners include the Massachusetts Institute of Technology (USA) and IBM-Research Africa</p>
                    </div>
                    
                   
                 
                </div>
                <div class="more_content">
                    <div class="row">
                        <div class="col-lg-8">
                      
                        </div>
                        <div class="col-lg-4">
                        <ul class="media">
                            <li><a href="#"><span class="fa fa-facebook"></span></a></li>
                            <li><a href="#"><span class="fa fa-twitter"></span></a></li>
                        </ul>
                        </div>
                    </div>
                </div>
               
            </div>
            <!---------col-end---------->
            </div>
            <div class="col-lg-2 col-md-12">
            <!---------enpty_column----------->
            </div>
            <!----------row-end---------->
        </div>
    </div>
</section>
</template>